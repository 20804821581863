import mapValues from 'lodash/mapValues';
import stateCssSelectors from 'styles/core/_state.scss';
import responsiveCssSizes from 'styles/core/_responsive.scss';


const stateClasses = mapValues(stateCssSelectors, (s) => s.substring(1));
const responsiveSizes = mapValues(responsiveCssSizes, (r) => parseInt(r.replace('px', '')), 10);

const getElementClass = (rootClass, elementName) => {
    return `${rootClass}__${elementName}`;
};

const getModifierClasses = (rootClass, variants) => {
    variants = Array.isArray(variants) ? variants : (variants ? [variants] : []);
    return variants.map(variant => `${rootClass}--${variant}`);
};


const DOC_TITLE_SEPERATOR = '|';


export {
    stateClasses,
    responsiveSizes,
    getElementClass,
    getModifierClasses,
    DOC_TITLE_SEPERATOR
};
