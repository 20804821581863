import VueRouter from 'vue-router';


export default class Router extends VueRouter {
    constructor (options) {
        super(options);
        this.defaultRedirect = options.defaultRedirect || '';
    }

    redirect () {
        const { redirect } = this.currentRoute.query;
        this.push(redirect || { name: this.defaultRedirect });
    }

    push (location, { preserveQuery = false } = {}) {
        if (preserveQuery && this.currentRoute.query) {
            location.query = this.currentRoute.query;
        }
        return super.push.apply(this, arguments);
    }
};
