export default {
    computed: {
        currentRouteRedirect () {
            const { path } = this.$route;
            return `redirect_to=${encodeURIComponent(path)}`;
        },
        accountUrl () {
            return `/account/info/?${this.currentRouteRedirect}`;
        },
        loginUrl () {
            return `/login/?${this.currentRouteRedirect}`;
        },
        signupUrl () {
            return `/signup/?${this.currentRouteRedirect}`;
        }
    }
};
