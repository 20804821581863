<template>
    <div class='app'
        :class='{ 
            "app--supports-hairlines": supportsHairlines,
            "app--chrome": isChrome
        }'
        :data-scroll-direction='scrollDir'>

        <AppHeader />
        <AppLogo />
        <AppMain />
        <AppFooter />

        <!--<GdprBar />-->

        <DevGrid v-if='isDevGridVisible' />
    </div>
</template>

<script>
import throttle from 'lodash/throttle';
import { mapState } from 'vuex';

import { DOC_TITLE_SEPERATOR } from '@/utils/markup';
import AppHeader from '@/components/AppHeader';
import AppLogo from '@/components/AppLogo';
import AppMain from '@/components/AppMain';
import AppFooter from '@/components/AppFooter';

// import GdprBar from '@/components/elements/GdprBar';

import DevGrid from '@/components/DevGrid';


export default {
    components: {
        AppHeader,
        AppLogo,
        AppMain,
        AppFooter,
        // GdprBar,
        DevGrid
    },
    data: () => ({
        isDevGridVisible: false,
        isChrome: false,
        supportsHairlines: false
    }),
    computed: {
        ...mapState('app', [
            'scrollDir',
            'viewTitle'
        ])
    }, 
    methods: {
        updateScrollData () {
            this.rafIsTicking = false;

            this.$store.dispatch(
                'app/updateScrollData',
                window.pageYOffset || document.documentElement.scrollTop
            );
        },
        scroll: throttle(function () {
            if (!this.rafIsTicking) {
                window.requestAnimationFrame(this.updateScrollData);
            }
            this.rafIsTicking = true;
        }, 200, { leading: true }, true),

        keyup (e) {
            if (e.key == 'g' && e.ctrlKey) {
                this.isDevGridVisible = !this.isDevGridVisible;
            }
        },

        detectHairlineSupport () {
            if (window.devicePixelRatio && devicePixelRatio >= 2) {
                var tester, testerHeight;

                tester = document.createElement('div');
                tester.style.border = '.5px solid transparent';
                document.body.appendChild(tester);
                testerHeight = tester.offsetHeight;
                document.body.removeChild(tester);

                this.supportsHairlines = testerHeight === 1;
            }
        },
        detectChrome () {
            this.isChrome = !!window.chrome;
        }
    },
    watch: {
        viewTitle (newViewTitle) {
            const sep = DOC_TITLE_SEPERATOR;
            const titleBuf = document.title.split(sep);
            const rootTitle = titleBuf[titleBuf.length - 1].trim();
            if (typeof newViewTitle !== 'string') {
                newViewTitle = 'Not Found 404';
            }
            document.title = newViewTitle
                ? `${newViewTitle} ${sep} ${rootTitle}`
                : rootTitle;
        }
    },
    created () {
        this.detectHairlineSupport();
        this.detectChrome();
    },
    mounted () {
        this.updateScrollData();
        window.addEventListener('scroll', this.scroll);
        window.addEventListener('keyup', this.keyup);
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.scroll);
        window.removeEventListener('keyup', this.keyup);
    }
};
</script>
