const state = () => ({
    scrollTop: 0,
    scrollDir: 'down',
    hasSwappingHeaders: false,
    hasWhiteHeader: false,
    viewTitle: ''
});

const getters = {
    isScrolled: (state) => state.scrollTop > 100,
    showMainNavOnScroll: (state) => !state.hasSwappingHeaders
};

const mutations = {
    setScrollTop: (state, scrollTop) => {
        state.scrollTop = scrollTop;
    },
    setScrollDir: (state, scrollDir) => {
        state.scrollDir = scrollDir;
    },
    setHasSwappingHeaders: (state, hasSwappingHeaders) => {
        state.hasSwappingHeaders = hasSwappingHeaders;
    },
    setHasWhiteHeader: (state, hasWhiteHeader) => {
        state.hasWhiteHeader = hasWhiteHeader;
    },
    setViewTitle: (state, viewTitle) => {
        state.viewTitle = viewTitle;
    }
};

const actions = {
    updateScrollData: ({ commit, state }, newScrollTop) => {
        const oldScrollTop = state.scrollTop;
        if (newScrollTop !== oldScrollTop) {
            commit('setScrollDir', newScrollTop > oldScrollTop ? 'down' : 'up');
            commit('setScrollTop', newScrollTop);
        }
    },

    registerSwappingHeader: ({ commit }) => {
        commit('setHasSwappingHeaders', true);
    },
    unregisterSwappingHeader: ({ commit }) => {
        commit('setHasSwappingHeaders', false);
    },

    registerWhiteHeader: ({ commit }) => {
        commit('setHasWhiteHeader', true);
    },
    unregisterWhiteHeader: ({ commit }) => {
        commit('setHasWhiteHeader', false);
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
