import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';


Vue.use(VueApollo);

const graphqlEndpoint = `${window.location.host}/graphql/`;
const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
const wsGraphqlEndpoint = `${wsProtocol}://${graphqlEndpoint}`;
const httpGraphqlEndpoint = `${window.location.protocol}//${graphqlEndpoint}`;

const httpLink = new HttpLink({
    uri: httpGraphqlEndpoint
});

const wsLink = new WebSocketLink({
    uri: wsGraphqlEndpoint,
    options: {
        reconnect: true,
        timeout: 60000
    }
});

// Split based on operation type -- subscriptions go to the
// websocket link, everything else to http.
const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

const cache = new InMemoryCache();

const client = new ApolloClient({
    cache,
    link
});

const provider = new VueApollo({
    defaultClient: client
});

export {
    client,
    provider
};

export default provider;
