<template>
    <div class='applogo' :class='{ 
        "applogo--is-white": hasWhiteHeader && !isScrolled
    }'>

        <!-- DESKTOP ------------------------------------------->
        <transition name='fade'>
            <div class='applogo__logo' v-show='showLogo'>
                <router-link v-html='logoText' class='text text--logo' :to='{name: "home"}' aria-label="Home"></router-link>
            </div>
        </transition>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { responsiveSizes } from '@/utils/markup';


export default {
    data: () => ({
        winWidth: window.innerWidth
    }),
    computed: {
        ...mapState('app', [
            'hasWhiteHeader'
        ]),
        ...mapGetters('app', [
            'isScrolled',
            'showMainNavOnScroll'
        ]),
        logoText () {
            const title = 'A24 Auctions';

            return title.replace(/\S/g, '<span data-ch="c-$&">$&</span>');
        },
        showLogo () {
            // when the mobilenav is showing, always hide logo on scroll;
            // otherwise, still show it if it's explicitly set to show
            if (this.winWidth <= responsiveSizes.MOBILENAV_MAX) {
                return !this.isScrolled;
            } else {
                return !this.isScrolled || this.showMainNavOnScroll;
            }
        }
    },
    mounted () {
        window.addEventListener('resize', this.resize);
        window.addEventListener('orientationchange', this.orientationchange);
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('orientationchange', this.orientationchange);
    },
    methods: {
        resize () {
            this.winWidth = window.innerWidth;
        },
        orientationchange () {
            this.winWidth = window.innerWidth;
        }
    }
}
</script>
