

/**
 * Wait for a given condition in a store to be satisfied.
 *
 * Returns a Promise that resolves when the return value
 * of `watchFn` satisfies the `condition` function.
 *
 * `watchFn` takes the same arguments as the first argument of Vuex's store.watch method.
 * `condition` by default just checks whether the watched value is truthy.
 * `timeout` may be passed optionally to have the Promise rejected after
 *  N milliseconds if it hasn't resolved by then.
 *
 * Example usage:
 *
 * waitForState(
 *     this.$store,
 *     (state, getters) => state.appReadyState,
 *     (value) => value === 'ready',
 *     3000
 * ).then(() => {
 *     console.log('App is ready!');
 * }).catch(() => {
 *     console.log('App not ready after 3 seconds');
 * });
 */
const waitForState = (store, watchFn, condition = (value) => !!value, timeout) => {
    return new Promise((resolve, reject) => {
        if (condition(watchFn(store.state, store.getters))) {
            resolve();
        } else {
            const unwatch = store.watch(watchFn, (value) => {
                if (condition(value)) {
                    unwatch();
                    resolve();
                }
            });
        }

        if (timeout) {
            window.setTimeout(reject, timeout);
        }
    });
};

/**
 * Wait for all the given conditions in a store to be satisfied.
 *
 * Returns a Promise that resolves when each of the `requirements` have
 * been satisfied.
 *
 * `requirements` is an array of individual requirements, where each
 * requirement is an array containing the positional arguments 1...n
 * that `waitForState` accepts.
 *
 * Example usage:
 *
 * waitForAll(
 *     this.$store,
 *     [
 *         [(state, getters) => state.appState,
 *          (value) => value === 'ready',
 *          3000],
 *         [(state, getters) => getters['module/isLoaded']]
 *     ]
 * ).then(() => {
 *     console.log('App is ready and module is loaded!');
 * }).catch(() => {
 *     console.log('App not ready or module not loaded after 3 seconds');
 * });
 */
const waitForAll = (store, requirements = []) => {
    return Promise.all(requirements.map(
        r => waitForState(store, r[0], r[1], r[2])
    ));
};


export {
    waitForState,
    waitForAll
};
