import Vue from 'vue';
import Router from './Router';
import store from '@/store';
import { states as auctionStates } from '@/utils/auction';
import { stateClasses } from '@/utils/markup';
import { waitForState } from '@/utils/store';

const HomeView = () => import(/* webpackChunkName: "home-view" */ '@views/HomeView');

const AccountView = () => import(/* webpackChunkName: "account-view" */ '@views/account/AccountView');
const AccountDetailsView = () => import(/* webpackChunkName: "account-details-view" */ '@views/account/AccountDetailsView');
const AccountBidHistoryView = () => import(/* webpackChunkName: "account-bid-history-view" */ '@views/account/AccountBidHistoryView');
const AccountTransactionsView = () => import(/* webpackChunkName: "account-transactions-view" */ '@views/account/AccountTransactionsView');

const CheckoutView = () => import(/* webpackChunkName: "checkout-view" */ '@views/CheckoutView');

const AuctionsView = () => import(/* webpackChunkName: "auctions-view" */ '@views/auctions/AuctionsView');
const AuctionsIndexView = () => import(/* webpackChunkName: "auctions-index-view" */ '@views/auctions/AuctionsIndexView');
const AuctionsDetailView = () => import(/* webpackChunkName: "auctions-detail-view" */ '@views/auctions/AuctionsDetailView');
const AuctionsLotView = () => import(/* webpackChunkName: "auctions-lot-view" */ '@views/auctions/AuctionsLotView');

const TitlesView = () => import(/* webpackChunkName: "titles-view" */ '@views/titles/TitlesView');
const TitlesIndexView = () => import(/* webpackChunkName: "titles-index-view" */ '@views/titles/TitlesIndexView');
const TitlesDetailView = () => import(/* webpackChunkName: "titles-detail-view" */ '@views/titles/TitlesDetailView');

const PageView = () => import(/* webpackChunkName: "page-view" */ '@views/PageView');
const NotFoundView = () => import(/* webpackChunkName: "not-found-view" */ '@views/NotFoundView');

Vue.use(Router);


const requireLoginGuard = async (to, from, next) => {
    await waitForState(store, state => state.auth.isReady);
    if (store.state.auth.isAuthenticated) {
        next();
    } else {
        // Redirect to Nexus login page
        window.location.replace(`/login/?redirect_to=${encodeURIComponent(to.path)}`);
    }
};


export default new Router({
    base: '/',
    mode: 'history',
    linkActiveClass: stateClasses.ACTIVE,
    linkExactActiveClass: stateClasses.ACTIVE_EXACT,
    defaultRedirect: 'home',
    scrollBehavior: (to, from, savedPosition) => savedPosition || { x: 0, y: 0 },
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/account/',
            name: 'account',
            component: AccountView,
            beforeEnter: requireLoginGuard,
            redirect: { name: 'account-details' },
            children: [
                {
                    path: 'details/',
                    name: 'account-details',
                    component: AccountDetailsView
                },
                {
                    path: 'bid-history/',
                    name: 'account-bid-history',
                    component: AccountBidHistoryView
                },
                {
                    path: 'transactions/',
                    name: 'account-transactions',
                    component: AccountTransactionsView
                }
            ]
        },
        {
            path: '/checkout/:orderId/',
            name: 'checkout',
            component: CheckoutView,
            props: true,
            beforeEnter: requireLoginGuard
        },
        {
            path: '/auctions/',
            component: AuctionsView,
            redirect: { name: 'home' },
            children: [
                {
                    path: 'open/',
                    name: 'open-auctions-index',
                    component: AuctionsIndexView,
                    props: {
                        auctionState: auctionStates.OPEN
                    }
                },
                {
                    path: 'soon/',
                    name: 'upcoming-auctions-index',
                    component: AuctionsIndexView,
                    props: {
                        auctionState: auctionStates.UPCOMING
                    }
                },
                {
                    path: 'closed/',
                    name: 'closed-auctions-index',
                    component: AuctionsIndexView,
                    props: {
                        auctionState: auctionStates.CLOSED
                    }
                },
                {
                    path: ':auctionSlug/',
                    name: 'auctions-detail',
                    component: AuctionsDetailView,
                    props: true
                },
                {
                    path: ':auctionSlug/:itemSlug/',
                    name: 'auctions-lot',
                    component: AuctionsLotView,
                    props: true
                }
            ]
        },
        {
            path: '/titles/',
            component: TitlesView,
            children: [
                {
                    path: '',
                    name: 'titles-index',
                    component: TitlesIndexView
                },
                {
                    path: ':titleSlug/',
                    name: 'titles-detail',
                    component: TitlesDetailView,
                    props: true
                }
            ]
        },
        {
            path: '/:pageSlug/',
            name: 'page',
            component: PageView,
            props: true
        },
        {
            path: '*',
            component: NotFoundView
        }
    ]
});
