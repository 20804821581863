let rudderstackScriptCheckRetryCount = 0;

export default {
    methods: {
        // Rudderstack tracking

        rudderstackTrack (target, data) {
            if (window.A24Consent && window.A24Consent.state.rudderstack.isReady) {
                if (target === 'user') {
                    this.rudderstackIdentifyUser(data);
                } else if (target === 'page') {
                    this.rudderstackTrackPage(data);
                }
            } else {
                if (rudderstackScriptCheckRetryCount < 200) {
                    setTimeout(() => {
                        rudderstackScriptCheckRetryCount++;
                        this.rudderstackTrack(target, data);
                    }, 60);
                }
            }
        },
        rudderstackIdentifyUser (user) {
            if (!user) return;
            window.rudderanalytics.identify(
                (user && user.nexusId) || '',
                user && {
                    ...this.getRudderstackBaseProps(),
                    fullName: user.fullName,
                    shortName: user.shortName,
                    email: user.email
                }
            );
        },
        rudderstackTrackPage (data) {
            window.rudderanalytics.page('', '', {
                ...this.getRudderstackBaseProps(),
                ...(data && { ...data })
            });
        },
        getRudderstackBaseProps () {
            return {
                affiliation: 'A24 Auctions',
                customer_id_source: !window.rudderanalytics.getUserId() ? '' : 'nexus'
            };
        }
    }
};
