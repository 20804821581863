import { client as apolloClient } from '@/apollo';
import { CurrentUser as CurrentUserGql } from '@gql/accounts/queries.gql';


const state = () => ({
    isAuthenticated: false,
    isReady: false
});

const getters = {};

const mutations = {
    setIsAuthenticated: (state, isAuthenticated) => {
        state.isAuthenticated = isAuthenticated;
        state.isReady = true;
    }
};

const actions = {
    async loadCurrentUser ({ commit }) {
        const { data } = await apolloClient.query({
            query: CurrentUserGql,
            fetchPolicy: 'network-only'
        });

        commit('setIsAuthenticated', !!data.currentUser);
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
