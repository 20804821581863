import 'intersection-observer';

import 'styles/main.scss';

import Vue from 'vue';

import App from './App';
import store from './store';
import router from './router';
import apolloProvider from './apollo';

import Scrollbars from './style/Scrollbars';


store.dispatch('auth/loadCurrentUser');

/* eslint-disable no-new */
new Scrollbars();

new Vue({
    el: '#app',
    store,
    router,
    apolloProvider,
    render: h => h(App)
});

if (SENTRY_DSN) { // eslint-disable-line no-undef
    Promise.all([
        import('@sentry/browser'),
        import('@sentry/integrations')
    ]).then(([Sentry, SentryIntegrations]) => {
        Sentry.init({
            dsn: SENTRY_DSN, // eslint-disable-line no-undef
            environment: SENTRY_ENV, // eslint-disable-line no-undef
            integrations: [new SentryIntegrations.Vue({ Vue, logErrors: true })]
        });
    });
}
