<template>
    <router-link class='underlink'
        v-if='route'
        :to='route'
        :target='target'
        :class='[modifierClasses]'
        :aria-label="route && route.name">
        <slot name='before'></slot>
        <span class='underlink__content'>
            <slot></slot>
        </span>
        <slot name='after'></slot>
    </router-link>

    <a class='underlink'
        v-else-if='href'
        :href='href'
        :target='target'
        :class='[modifierClasses]'
    >
        <slot name='before'></slot>
        <span class='underlink__content'>
            <slot></slot>
        </span>
        <slot name='after'></slot>
    </a>

    <span class='underlink'
        v-else
        :class='[modifierClasses]'>
        <slot name='before'></slot>
        <span class='underlink__content'>
            <slot></slot>
        </span>
        <slot name='after'></slot>
    </span>
</template>

<script>
import { getModifierClasses } from '@/utils/markup';


export default {
    props: {
        route: {
            type: Object,
            required: false
        },
        variants: {
            type: [String, Array],
            default: ''
        },
        href: {
            type: String,
            default: ''
        },
        target: {
            type: String,
            default: ''
        }
    },
    computed: {
        modifierClasses () {
            return getModifierClasses('underlink', this.variants);
        }
    }
};
</script>
