<template>
    <div class='tabular' :class='[modifierClasses]'>
        <div class='tabular__label'>
            <span class='tabular__label__content tabular__label__content--phone'
                v-if='labelPhone'
                v-html='labelPhone' />
            <span class='tabular__label__content'
                v-html='label'
                :class='{"tabular__label__content--notphone" : labelPhone}' />
        </div>
        <div class='tabular__value' :class='[valueModifierClasses]'>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { getModifierClasses } from '@/utils/markup';

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        labelPhone: {
            type: String,
            default: ''
        },
        variants: {
            type: [String, Array],
            default: ''
        },
        valueVariants: {
            type: [String, Array],
            default: ''
        }
    },
    computed: {
        modifierClasses () {
            return getModifierClasses('tabular', this.variants);
        },
        valueModifierClasses () {
            return getModifierClasses('tabular__value', this.valueVariants);
        }
    }
}
</script>
