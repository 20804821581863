<template>
    <footer class='appfooter'>
        <div class='appfooter__main'>
            <nav class='appfooter__main__nav'>
                <Tabular label='A24'>
                    <UnderLink v-if='aboutPage' :route='{name: "page", params: { pageSlug: aboutPage.slug }}'>{{ aboutPage.title }}</UnderLink>
                    <UnderLink v-else-if='globalSettings' :route='{name: "home"}'>Auctions</UnderLink>
                    <UnderLink :route='{name: "titles-index"}'>Browse Titles</UnderLink>
                    <UnderLink v-if='globalSettings' :route='{name: "page", params: { pageSlug: globalSettings.biddingInfoPage.slug }}'>{{ globalSettings.biddingInfoPage.title }}</UnderLink>
                    <span class='appfooter__copyright'>(c) {{ year }}, A24</span>
                </Tabular>
            </nav>
            <div class='appfooter__main__logo'>
                <a href='//a24films.com' target='_blank' rel='noopener' class='appfooter__img-logo'>A24</a>
            </div>
            <nav class='appfooter__main__legalnav'>
                <Tabular v-if='globalSettings' label='Legal'>
                    <UnderLink
                        v-for='page in globalSettings.legalPages'
                        :key='page.id'
                        :route='{name: "page", params: { pageSlug: page.slug }}'>
                        {{page.title}}
                    </UnderLink>

                    <a class='appfooter__privacy-prefs-link' href='#' data-cc='c-settings'>
                        Do Not Sell or Share My Personal Information
                    </a>
                </Tabular>
            </nav>
        </div>
    </footer>
</template>

<script>
import UnderLink from '@/components/elements/UnderLink';
import Tabular from '@/components/elements/Tabular';
import { GlobalSettings as GlobalSettingsGql } from '@gql/about/queries.gql';

export default {
    apollo: {
        globalSettings: {
            query: GlobalSettingsGql
        }
    },
    data: () => ({
        globalSettings: null
    }),
    computed: {
        aboutPage () {
            const { globalSettings } = this;
            return globalSettings ? globalSettings.aboutPage : null;
        },
        year () {
            const today = new Date();
            return today.getFullYear();
        }
    },
    components: {
        UnderLink,
        Tabular
    }
};
</script>
