const states = {
    UPCOMING: 'UPCOMING',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
};

const orderingMethods = {
    SOONEST_OPENING: 'SOONEST_OPENING',
    SOONEST_CLOSING: 'SOONEST_CLOSING',
    MOST_RECENTLY_CLOSED: 'MOST_RECENTLY_CLOSED',
    MOST_RECENTLY_OPENED: 'MOST_RECENTLY_OPENED'
};

const lotStanding = {
    NONE: 'NONE',
    OUTBID: 'OUTBID',
    LEADING: 'LEADING',
    WON: 'WON'
};

const layouts = {
    LEFT_TOP: 'LEFT_TOP',
    LEFT_CENTER: 'LEFT_CENTER',
    LEFT_BOTTOM: 'LEFT_BOTTOM',
    CENTER_TOP: 'CENTER_TOP',
    CENTER_CENTER: 'CENTER_CENTER',
    CENTER_BOTTOM: 'CENTER_BOTTOM',
    RIGHT_TOP: 'RIGHT_TOP',
    RIGHT_CENTER: 'RIGHT_CENTER',
    RIGHT_BOTTOM: 'RIGHT_BOTTOM'
};

const filterByState = (objects, state) => objects.filter(x => x.state === state);

const getUpcoming = (objects) => filterByState(objects, states.UPCOMING);
const getOpen = (objects) => filterByState(objects, states.OPEN);
const getClosed = (objects) => filterByState(objects, states.CLOSED);

const getStateLabel = (state) => {
    switch (state) {
    case states.UPCOMING:
        return 'Soon';
    case states.OPEN:
        return 'Open';
    case states.CLOSED:
        return 'Closed';
    default:
        return '';
    }
};

const getStateClass = (state) => {
    switch (state) {
    case states.UPCOMING:
        return 'soon';
    case states.OPEN:
        return 'open';
    case states.CLOSED:
        return 'closed';
    default:
        return '';
    }
};

const getLayoutClass = (layout) => {
    switch (layout) {
    case layouts.LEFT_TOP:
        return 'left-top';
    case layouts.LEFT_CENTER:
        return 'left-center';
    case layouts.LEFT_BOTTOM:
        return 'left-bottom';
    case layouts.CENTER_TOP:
        return 'center-top';
    case layouts.CENTER_CENTER:
        return 'center-center';
    case layouts.CENTER_BOTTOM:
        return 'center-bottom';
    case layouts.RIGHT_TOP:
        return 'right-top';
    case layouts.RIGHT_CENTER:
        return 'right-center';
    case layouts.RIGHT_BOTTOM:
        return 'right-bottom';
    default:
        return '';
    }
};

const isReserveUnmet = (lot) => {
    const { reservePrice, currentPrice } = lot;
    return reservePrice && reservePrice > currentPrice;
};


export {
    states,
    orderingMethods,
    lotStanding,
    layouts,
    getUpcoming,
    getOpen,
    getClosed,
    getStateLabel,
    getStateClass,
    getLayoutClass,
    isReserveUnmet
};
