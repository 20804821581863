<template>
    <header class='appheader' :class='{
        "appheader--mobilenav-open": isMobilenavOpen,
        "appheader--is-white": hasWhiteHeader && !isScrolled
    }'>

        <!-- DESKTOP ------------------------------------------->
        <!-- main nav -->
        <transition name='fade'>
            <div class='appheader__main' v-show='!isScrolled || showMainNavOnScroll'>
                <div class='appheader__main__logo-small'>
                    <transition name='fade'>
                        <!--<router-link class='text text--logo-small' :to='{name: "home"}'>A24 Auctions</router-link>-->
                    </transition>
                </div>
                <nav class='appheader__main__nav' tabindex="0">
                    <div class='appheader__main__nav__left'>
                        <UnderLink
                            variants='no-line-default'
                            :route='{name: "open-auctions-index"}'
                            :class='getDisabledStateClass(openAuctionsCheck)'>
                            Open
                        </UnderLink>
                        <UnderLink
                            variants='no-line-default'
                            :route='{name: "upcoming-auctions-index"}'
                            :class='getDisabledStateClass(upcomingAuctionsCheck)'>
                            Coming Soon
                        </UnderLink>
                        <UnderLink
                            variants='no-line-default'
                            :route='{name: "closed-auctions-index"}'
                            :class='getDisabledStateClass(closedAuctionsCheck)'>
                            Closed
                        </UnderLink>
                    </div>
                    <div class='appheader__main__nav__divider'>
                        <span class='appheader__divider'>/</span>
                    </div>
                    <div class='appheader__main__nav__right'>
                        <UnderLink
                            variants='no-line-default'
                            :route='{name: "titles-index"}'>Browse Titles
                        </UnderLink>
                        <UnderLink
                            v-if='globalSettings'
                            variants='no-line-default'
                            :route='{ name: "page", params: { pageSlug: globalSettings.biddingInfoPage.slug }}'>
                            {{ globalSettings.biddingInfoPage.title }}
                        </UnderLink>
                    </div>
                </nav>
                <nav class='appheader__main__usernav'>
                    <template v-if='authIsReady'>
                        <span class='appheader__divider appheader__divider--condensed'>/</span>
                        <UnderLink
                            variants='no-line-default'
                            v-if='currentUser'
                            :route='{ name: "account" }'>
                            <span class='appheader__main__fullname'>{{ fullName }}</span>
                            <span class='appheader__main__shortname'>{{ shortName }}</span>
                            <template v-if='hasAlert' v-slot:after>
                                <AlertDot />
                            </template>
                        </UnderLink>
                        <template v-else>
                            <UnderLink
                                variants='no-line-default'
                                :href='loginUrl'>
                                Log In
                            </UnderLink>
                            <span class='appheader__divider appheader__divider--normal'>/</span>
                            <UnderLink
                                variants='no-line-default'
                                :href='signupUrl'>
                                Sign Up
                            </UnderLink>
                        </template>
                    </template>
                </nav>
            </div>
        </transition>


        <!-- MOBILE ------------------------------------------->
        <!-- phone nav (that lives in header) -->
        <div class='appheader__main-mobile'>
            <div class='appheader__main-mobile__hamburger'>
                <a class='appheader__hamburger' @click='toggleMobilenav'><span>Menu</span></a>
            </div>
            <nav class='appheader__main-mobile__usernav'>
                <template v-if='authIsReady'>
                    <UnderLink v-if='currentUser'
                        variants='no-line-default'
                        :route='{ name: "account" }'>
                        {{ shortName }}
                        <template v-if='hasAlert' v-slot:after>
                            <AlertDot />
                        </template>
                    </UnderLink>
                    <UnderLink v-else
                        variants='no-line-default'
                        :href='signupUrl'>
                        Sign Up
                    </UnderLink>
                </template>
            </nav>
        </div>

        <!-- mobilenav overlay -->
        <transition name='fade'>
            <div class='appheader__mobilenav' v-if='isMobilenavOpen'>
                <span>&mdash;</span>

                <UnderLink
                    variants='no-line-default'
                    @click.native='toggleMobilenav'
                    :route='{name: "open-auctions-index"}'
                    :class='getDisabledStateClass(openAuctionsCheck)'>
                    Open
                </UnderLink>
                <UnderLink
                    variants='no-line-default'
                    @click.native='toggleMobilenav'
                    :route='{name: "upcoming-auctions-index"}'
                    :class='getDisabledStateClass(upcomingAuctionsCheck)'>
                    Coming Soon
                </UnderLink>
                <UnderLink
                    variants='no-line-default'
                    @click.native='toggleMobilenav'
                    :route='{name: "closed-auctions-index"}'
                    :class='getDisabledStateClass(closedAuctionsCheck)'>
                    Closed
                </UnderLink>

                <span>&mdash;</span>

                <UnderLink
                    variants='no-line-default'
                    @click.native='toggleMobilenav'
                    :route='{name: "titles-index"}'>Browse Titles
                </UnderLink>
                <UnderLink
                    v-if='globalSettings'
                    variants='no-line-default'
                    @click.native='toggleMobilenav'
                    :route='{ name: "page", params: { pageSlug: globalSettings.biddingInfoPage.slug }}'>
                    {{ globalSettings.biddingInfoPage.title }}
                </UnderLink>

                <span>&mdash;</span>

                <template v-if='authIsReady'>
                    <UnderLink
                        variants='no-line-default'
                        @click.native='toggleMobilenav'
                        v-if='currentUser'
                        :route='{ name: "account" }'>
                        {{ fullName }}
                    </UnderLink>

                    <template v-else>
                        <UnderLink
                            variants='no-line-default'
                            @click.native='toggleMobilenav'
                            :href='loginUrl'>
                            Log In
                        </UnderLink>
                        <UnderLink
                            variants='no-line-default'
                            @click.native='toggleMobilenav'
                            :href='signupUrl'>
                            Sign Up
                        </UnderLink>
                    </template>
                </template>

                <span>&mdash;</span>
            </div>
        </transition>

    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
    CurrentUser as CurrentUserGql,
    PeekAtAccountDetails as PeekAtAccountDetailsGql
} from '@gql/accounts/queries.gql';
import { GlobalSettings as GlobalSettingsGql } from '@gql/about/queries.gql';
import { CheckAuctionsByState as CheckAuctionsByStateGql } from '@gql/auctions/queries.gql';
import { states as auctionStates } from '@/utils/auction';
import { stateClasses } from '@/utils/markup';
import UnderLink from '@/components/elements/UnderLink';
import AlertDot from '@/components/elements/AlertDot';
import accountLinksMixin from '@/components/mixins/accountLinksMixin';
import analyticsMixin from '@/components/mixins/analyticsMixin';


export default {
    mixins: [accountLinksMixin, analyticsMixin],
    apollo: {
        currentUser: {
            query: CurrentUserGql,
            skip: vm => {
                return !vm.authIsReady || !vm.isAuthenticated;
            }
        },
        accountDetails: {
            query: PeekAtAccountDetailsGql,
            update: data => data.myAccountDetails,
            skip: vm => {
                return !vm.authIsReady || !vm.isAuthenticated;
            }
        },
        globalSettings: {
            query: GlobalSettingsGql
        },
        openAuctionsCheck: {
            query: CheckAuctionsByStateGql,
            update: data => data.auctions,
            variables: {
                state: auctionStates.OPEN
            }
        },
        upcomingAuctionsCheck: {
            query: CheckAuctionsByStateGql,
            update: data => data.auctions,
            variables: {
                state: auctionStates.UPCOMING
            }
        },
        closedAuctionsCheck: {
            query: CheckAuctionsByStateGql,
            update: data => data.auctions,
            variables: {
                state: auctionStates.CLOSED
            }
        }
    },
    data: () => ({
        isMobilenavOpen: false,
        currentUser: null,
        accountDetails: null,
        openAuctionsCheck: null,
        upcomingAuctionsCheck: null,
        closedAuctionsCheck: null
    }),
    computed: {
        ...mapState('auth', {
            authIsReady: 'isReady',
            isAuthenticated: 'isAuthenticated'
        }),
        ...mapState('app', [
            'hasWhiteHeader'
        ]),
        ...mapGetters('app', [
            'isScrolled',
            'showMainNavOnScroll'
        ]),
        fullName () {
            const { currentUser } = this;
            return currentUser ? this.truncateName(currentUser.fullName, 32) : '';
        },
        shortName () {
            const { currentUser } = this;
            return currentUser ? this.truncateName(currentUser.shortName, 16) : '';
        },
        hasAlert () {
            const { accountDetails } = this;
            if (!accountDetails) {
                return false;
            }
            if (!accountDetails.defaultPaymentInfo) {
                return true;
            }
            return accountDetails.pendingOrders.length > 0;
        }
    },
    methods: {
        getDisabledStateClass (check) {
            return {
                [stateClasses.DISABLED]: check && check.objects.length === 0
            };
        },
        toggleMobilenav () {
            this.isMobilenavOpen = !this.isMobilenavOpen;
        },
        truncateName (name, maxChars) {
            if (name) {
                return name.length < maxChars ? name : name.substring(0,maxChars - 1) + '\u2026';
            } else {
                return 'No Name';
            }
        }
    },
    watch: {
        currentUser (newCurrentUser) {
            if (newCurrentUser !== null) {
                this.rudderstackTrack('user', this.currentUser);
            }
        },
    },
    components: {
        UnderLink,
        AlertDot
    }
};
</script>
